import { Home, NotFound } from "./views";
import { Outlet, Navigate } from "react-router-dom";

interface IPrivateRoutes {
  auth: any;
  isMobile: boolean;
  isAndroid: boolean;
  isIOS: boolean;
  isPWA: boolean;
}

const privateRoutes = ({
  auth,
  isMobile = false,
  isAndroid = false,
  isIOS = false,
  isPWA = false,
}: IPrivateRoutes) => {
  const navigationRoute =
    isMobile && !isPWA
      ? isAndroid
        ? "/pwa/instruction/android"
        : isIOS
        ? "/pwa/instruction/ios"
        : "/pwa"
      : "/login";
  return [
    {
      path: "/",
      name: "Home",
      errorElement: <NotFound />,
      element: auth === null ? <Navigate to={navigationRoute} /> : <Outlet />,
      children: [
        {
          path: "/",
          name: "Home",
          element: <Home />,
        },
      ],
    },
  ];
};

export default privateRoutes;
