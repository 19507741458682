import React from "react";
import { Box, Button, CircularProgress, ButtonProps } from "@mui/material";
import styles from "./FormButton.module.scss";
import { styled } from "@mui/material/styles";

export const FormButton = ({ ...props }: any) => {
  const {
    color,
    loading,
    fullWidth,
    size,
    type,
    variant,
    label,
    style,
    onClick = null,
    buttonIcon = "",
    theme = "",
  } = props;

  const ThemeButton = styled(Button)<ButtonProps>(() => ({
    backgroundColor: "#000",
    border: 0,
    borderRadius: 0,
    color: "#FFFF00",
    padding: variant === "refresh" ? "12px 16px" : "12px 48px",
    width: variant === "refresh" ? 200 : "auto",
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    fontSize: "16px",
    lineHeight: "19.36px",
    "&:hover": {
      backgroundColor: "#000",
    },
  }));

  return (
    <Box sx={style}>
      {theme === "custom" ? (
        <ThemeButton
          color={color ?? "primary"}
          disabled={loading}
          fullWidth={fullWidth ?? false}
          size={size ?? "large"}
          type={type ?? "button"}
          variant={variant ?? "contained"}
          onClick={onClick}
        >
          {buttonIcon !== "" && buttonIcon}
          {label}{" "}
          {loading && <CircularProgress className={styles.spinner} size={20} />}
        </ThemeButton>
      ) : (
        <Button
          color={color ?? "primary"}
          disabled={loading}
          fullWidth={fullWidth ?? false}
          size={size ?? "large"}
          type={type ?? "button"}
          variant={variant ?? "contained"}
          onClick={onClick}
        >
          {buttonIcon !== "" && buttonIcon}
          {label}{" "}
          {loading && <CircularProgress className={styles.spinner} size={20} />}
        </Button>
      )}
    </Box>
  );
};
