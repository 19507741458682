import { Stack, CardContent } from "@mui/material";
import { ThemeCardDark } from "../Card/ThemeCard";
import styles from "./Card.module.scss";

const CardNumberClients = (props: any) => {
  const { auth } = props;
  return (
    <ThemeCardDark className={styles.card}>
      <CardContent className={styles.cardBody}>
        <Stack spacing={1}>
          <h3 className={styles.cardHeading}>{auth?.clients?.length}</h3>
          <p className={styles.cardSubtitle}>Total Clients Referred</p>
        </Stack>
      </CardContent>
    </ThemeCardDark>
  );
};

export default CardNumberClients;
