import { Stack, CardContent } from "@mui/material";
import { ThemeCardDark } from "../Card/ThemeCard";
import styles from "./Card.module.scss";

const CardServicesBooked = (props: any) => {
  const { auth } = props;

  return (
    <ThemeCardDark className={styles.card}>
      <CardContent className={styles.cardBody}>
        <Stack spacing={1}>
          <h3 className={styles.cardHeading}>{auth.servicesBooked.length}</h3>
          <p className={styles.cardSubtitle}>Number of Orders</p>
        </Stack>
      </CardContent>
    </ThemeCardDark>
  );
};

export default CardServicesBooked;
