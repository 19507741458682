import { Stack } from "@mui/material";
import styles from "./Footer.module.scss";
import { useNavigate } from "react-router-dom";
import authStore from "../../store/authStore";
import firebaseAuth from "../../utils/firebase";

const Footer = () => {
  const navigate = useNavigate();
  const { clearAuthData } = authStore((state: any) => state);

  return (
    <footer className={styles.footerContainer}>
      <Stack spacing={1} direction="row" className={styles.footerLeft}>
        <div className={styles.footerTitle1}>Connect</div>
        <img
          src="./images/footer_facebook.png"
          alt="Facebook"
          className={styles.footerIcon}
          onClick={() =>
            window.open("https://www.facebook.com/ServiceBackSG", "_blank")
          }
        />
        <img
          src="./images/footer_linkedin.png"
          alt="LinkedIn"
          className={styles.footerIcon}
          onClick={() =>
            window.open(
              "https://www.linkedin.com/company/serviceback/",
              "_blank"
            )
          }
        />
      </Stack>

      <Stack spacing={1} direction="row" className={styles.footerRight}>
        <div
          className={styles.logoutButton}
          onClick={() => {
            clearAuthData();
            firebaseAuth.signOut();
            navigate("/login");
          }}
        >
          Log Out
        </div>
        <div
          className={styles.supportButton}
          onClick={() => {
            window.open("https://wa.me/+6582615047?text=Hello%21", "_blank");
          }}
        >
          Support{" "}
          <img
            src="./images/message.png"
            alt="Message Icon Circle"
            className={styles.footerSupportIcon}
            onClick={() => alert("redirect to linkedin!")}
          />
        </div>
      </Stack>
    </footer>
  );
};

export default Footer;
