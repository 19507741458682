import { Navigate } from "react-router-dom";
import InstallPrompt from "../components/PWA/InstallPrompt";

const Index = () => {
  const isPWA = window.matchMedia("(display-mode: standalone)").matches;

  return <>{!isPWA ? <InstallPrompt /> : <Navigate to="/login" />}</>;
};

export default Index;
