import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { Grid, Box, Container, Typography } from "@mui/material";
import moment from "moment";
import styles from "./Home.module.scss";

import Layout from "../../layout/Layout";

import CardNumberClients from "../../components/Home/CardNumberClients";
import CardReferralEarned from "../../components/Home/CardReferralEarned";
import CardServicesBooked from "../../components/Home/CardServicesBooked";

import ClientHistoryTable from "../../components/Home/ClientHistoryTable";

import authStore from "../../store/authStore";
import CardReferralUnClaimed from "../../components/Home/CardReferralUnClaimed";
import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const Home = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { auth } = authStore((state: any) => state);
  console.log(auth);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [clientHistoryData, setClientHistoryData] = useState([]);

  const formatAppointmentData = (service: any, date: any) => {
    const type = formatServiceType(service);
    const momentDate = moment(date);
    let servicingUnits = 0;

    if (service["Servicing Units"] !== undefined) {
      servicingUnits = parseInt(service["Servicing Units"]);
    }

    return {
      type: type,
      date: momentDate.format("Do MMMM YYYY"),
      time: momentDate.format("h:mm a"),
      units: servicingUnits,
    };
  };

  const formatServiceType = (service: any) => {
    let type = "";
    if (service["Service"] !== undefined) {
      if (service["Service"] === "AC") {
        type = "Aircon Servicing";
      } else if (service["Service"] === "FL") {
        type = "Floor Servicing";
      }
    }
    return type;
  };

  const fetchServices = async (servicesBooked: any[]) => {
    if (servicesBooked.length <= 0) {
      setTableLoading(false);
      enqueueSnackbar(`No services found`, {
        variant: "error",
      });
      return false;
    }

    try {
      const response = await axios.post(
        `${BACKEND_URL}/services/`,
        {
          servicesBooked: servicesBooked,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.firebaseIdToken}`,
          },
        }
      );
      const { services } = response.data;

      const clonedClientHistoryData: any = [...clientHistoryData];

      services.forEach((userService: any) => {
        const service: any = userService.fields;

        let serviceDate: any = "";
        if (userService._rawJson.createdTime !== undefined) {
          serviceDate = moment(userService._rawJson.createdTime).format(
            "Do MMMM YYYY"
          );
        }

        clonedClientHistoryData.push({
          clientName: service["Client Name (from Client)"] ?? "",
          date: serviceDate,
          order: formatServiceType(service),
          orderValue: service["Contract Size"] ?? 0,
          commission: (service["Contract Size"] ?? 0) * 0.05,
        });
      });

      setClientHistoryData(clonedClientHistoryData.reverse());
      setTableLoading(false);
    } catch (err: any) {
      enqueueSnackbar(err.response.data.error, {
        variant: "error",
      });
      setTableLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Home | Handdi.io";
    fetchServices(auth.servicesBooked);
  }, []);

  return (
    <Layout>
      <Container maxWidth="lg">
        <Box className={styles.topHeader}>
          <Typography variant="h1" className={styles.title}>
            Partners referral dashboard
          </Typography>
        </Box>

        <Grid spacing={2} container className={styles.topRow}>
          <Grid item xs={12} sm={12}>
            <CardReferralEarned auth={auth} />
          </Grid>
        </Grid>
        <Grid spacing={2} container className={styles.topRow}>
          <Grid item xs={12} sm={4}>
            <CardReferralUnClaimed auth={auth} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CardServicesBooked auth={auth} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CardNumberClients auth={auth} />
          </Grid>
        </Grid>

        <Box className={styles.tableRow}>
          <ClientHistoryTable
            loading={tableLoading}
            rowData={clientHistoryData}
          />
        </Box>
      </Container>
    </Layout>
  );
};

export default Home;
