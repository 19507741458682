import { Grid, Link, Typography } from "@mui/material";
import styles from "./Header.module.scss";
import { useNavigate } from "react-router-dom";

const Header = (props: any) => {
  const navigate = useNavigate();

  return (
    <>
      <Grid container className={styles.container} alignItems="center">
        <Grid item>
          <Typography
            onClick={() => navigate("/")}
            variant="h6"
            component="h1"
            className={styles.iconText}
          >
            Handdi.io
          </Typography>
        </Grid>
        <Grid item className={styles.topButtonContainer}>
          <button
            className={`${styles.button} ${styles.buttonSupport}`}
            onClick={() => {
              window.open("https://wa.me/+6582615047?text=Hello%21", "_blank");
            }}
          >
            Support{" "}
            <img
              src="/images/message.png"
              alt="Cart"
              className={styles.buttonIcon}
            />
          </button>
        </Grid>
      </Grid>
    </>
  );
};

export default Header;
