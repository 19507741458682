import React, { Suspense, useEffect, useState } from "react";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import { SnackbarWrapper } from "./layout/SnackbarWrapper";

import { createEmotionCache } from "./utils/createEmotionCache";
import { CacheProvider } from "@emotion/react";

import "./base.scss";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import { CssBaseline } from "@mui/material";

import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import privateRoutes from "./config/privateRoutes";
import publicRoutes from "./config/publicRoutes";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import authStore from "./store/authStore";
import Offline from "./components/OfflineScreen";
import firebaseAuth from "./utils/firebase";
import moment from "moment";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const clientSideEmotionCache = createEmotionCache();

const App = (props: any) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const { auth, setAuthData } = authStore((state: any) => state);
  const isPWA = window.matchMedia("(display-mode: standalone)").matches;
  const router = createBrowserRouter([
    ...privateRoutes({
      auth,
      isMobile: isMobile,
      isAndroid: isAndroid,
      isIOS: isIOS,
      isPWA: isPWA,
    }),
    ...publicRoutes(),
  ]);
  const { emotionCache = clientSideEmotionCache } = props;

  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  const refreshToken = async () => {
    const idToken = await firebaseAuth.currentUser?.getIdToken(true);
    if (idToken) {
      setAuthData({
        ...auth,
        firebaseIdToken: idToken,
        expiresAt: moment().add(1, "hour").unix(),
      });
    }
  };
  //we need to check if the auth is expired if it is we can request new token and refresh the auth
  useEffect(() => {
    if (auth) {
      const expiresAt = auth.expiresAt;
      const now = moment().unix();
      if (now > expiresAt) {
        refreshToken();
      }
    }
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <QueryClientProvider client={queryClient}>
        <SnackbarWrapper>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Suspense fallback={<>test</>}>
              {isOnline && <RouterProvider router={router} />}
              {!isOnline && <Offline />}
            </Suspense>
          </ThemeProvider>
        </SnackbarWrapper>
      </QueryClientProvider>
    </CacheProvider>
  );
};

export default App;
