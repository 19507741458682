import { Box, Typography } from "@mui/material";
import styles from "./AuthHeader.module.scss";

const AuthHeader = (props: any) => {
  const { title, description } = props;

  return (
    <div className={styles.container}>
      <Box style={{ marginBottom: "19px", textAlign: "center" }}>
        <img src="./images/dashboard-charts.png" alt="Dashboard Logo" />
        <Typography variant="h1" component="h1" className={styles.title}>
          {title}
        </Typography>
        <Typography
          variant="body1"
          component="p"
          className={styles.description}
        >
          {description}
        </Typography>
      </Box>
    </div>
  );
};

export default AuthHeader;
